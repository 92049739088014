const FOCUS_OPTIONS = {
  input: "INPUT",
  listbox: "LISTBOX",
};

export class ComboBox extends HTMLElement {
  static tagName = "combo-box";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    window.requestAnimationFrame(() => {
      this.elements = {
        root: this,
        input: this.querySelector('[role="combobox"]'),
        listbox: this.querySelector('[role="listbox"]'),
        options: [...this.querySelectorAll('[role="option"]')],
        valueInput: this.querySelector('[data-combobox-target="value"]'),
      };
      this._focusedElement = null;
      this._filterValue = this.elements.input.value;
      this._currentOption = null;
      this._selectedOption = null;

      this.filteredOptions = [];

      this.#listen();
    });
  }

  disconnectedCallback() {
    this.#unlisten();
  }

  #listen() {
    this.elements.input.addEventListener("click", this.#onClick.bind(this));
    this.elements.input.addEventListener("focus", this.#onFocus.bind(this));
    this.elements.input.addEventListener("blur", this.#onBlur.bind(this));
    this.elements.input.addEventListener("keydown", this.#onKeyDown.bind(this));
    this.elements.input.addEventListener("keyup", this.#onKeyUp.bind(this));
    this.elements.listbox.addEventListener(
      "pointerover",
      this.#onListboxPointerover.bind(this),
    );
    this.elements.listbox.addEventListener(
      "pointerout",
      this.#onListboxPointerout.bind(this),
    );
    document.body.addEventListener(
      "pointerup",
      this.#onBackgroundPointerUp.bind(this),
      true,
    );
    this.elements.options.forEach((opt) => {
      opt.addEventListener("click", this.#onOptionClick.bind(this));
      opt.addEventListener("pointerover", this.#onOptionPointerover.bind(this));
    });
  }

  #unlisten() {
    this.elements.input.removeEventListener("click", this.#onClick.bind(this));
    this.elements.input.removeEventListener("focus", this.#onFocus.bind(this));
    this.elements.input.removeEventListener("blur", this.#onBlur.bind(this));
    this.elements.input.removeEventListener(
      "keydown",
      this.#onKeyDown.bind(this),
    );
    this.elements.input.removeEventListener("keyup", this.#onKeyUp.bind(this));
    document.body.removeEventListener(
      "pointerup",
      this.#onBackgroundPointerUp.bind(this),
      true,
    );
    this.elements.listbox.removeEventListener(
      "pointerover",
      this.#onListboxPointerover.bind(this),
    );
    this.elements.listbox.removeEventListener(
      "pointerout",
      this.#onListboxPointerout.bind(this),
    );
    this.elements.options.forEach((opt) => {
      opt.removeEventListener("click", this.#onOptionClick.bind(this));
      opt.removeEventListener(
        "pointerover",
        this.#onOptionPointerover.bind(this),
      );
    });
  }

  get filterValue() {
    return this._filterValue;
  }

  set filterValue(value) {
    this._filterValue = value;
    this.#filterOptions();
  }

  get selectedOption() {
    return this._selectedOption;
  }

  set selectedOption(node) {
    this._selectedOption = node;
    this.#setInputValue(this.selectedOption?.textContent);
    this.#setSelectedOptionStyle(node);

    // Update value
    this.elements.valueInput.value = node?.dataset?.value || "";

    // notify change
    this.elements.valueInput.dispatchEvent(
      new Event("programmatic-change", { bubbles: true }),
    );
  }

  get currentOption() {
    return this._currentOption;
  }

  set currentOption(node) {
    this._currentOption = node;

    this.#setCurrentOptionStyle(node);
    this.#setActiveDescendant(node);
  }

  get isOpen() {
    return !this.elements.listbox.hidden;
  }

  get focusedElement() {
    return this._focusedElement;
  }

  set focusedElement(value) {
    this._focusedElement = value;

    if (value === FOCUS_OPTIONS.input) {
      this.elements.root.classList.add("combobox--focus");
      this.#setActiveDescendant(false);
    } else {
      this.elements.root.classList.remove("combobox--focus");
    }

    if (value === FOCUS_OPTIONS.listbox) {
      this.elements.listbox.classList.add("combobox__listbox--focus");
      this.#setActiveDescendant(this.selectedOption);
    } else {
      this.elements.listbox.classList.remove("combobox__listbox--focus");
    }
  }

  open() {
    this.elements.listbox.hidden = false;
    this.elements.input.setAttribute("aria-expanded", "true");
  }

  close(force = false) {
    if (
      force ||
      (!Object.values(FOCUS_OPTIONS).includes(this.focusedElement) &&
        !this.hasHover)
    ) {
      const wasOpen = this.isOpen;
      this.#setCurrentOptionStyle(false);
      this.elements.listbox.hidden = true;
      this.elements.input.setAttribute("aria-expanded", "false");
      this.#setActiveDescendant(null);
      this.elements.root.classList.remove("combobox--focus");

      // Show selected option as you'd expect IF the box was open when we started
      // (Otherwise we'll reset the input, since )
      if (wasOpen) this.#setInputValue(this.selectedOption?.textContent);
    }
  }

  #onClick() {
    this.isOpen ? this.close(true) : this.open();
  }

  #onFocus() {
    this.filterValue = "";
    this.#setInputValue("");
    this.focusedElement = FOCUS_OPTIONS.input;
    this.currentOption = null;
    this.#setCurrentOptionStyle(null);
    this.#setSelectedOptionStyle(this.selectedOption);
  }

  #onBlur() {
    if (!this.elements.root.contains(document.activeElement)) {
      this.focusedElement = null;
    }
  }

  #onKeyDown(event) {
    if (event.ctrlKey || event.shiftKey) return;

    let prevent = false;
    switch (event.key) {
      case "Enter":
        if (!this.isOpen && this.selectedOption) {
          // Submit form if closed and an option is selected
          return;
        } else {
          this.selectedOption = this.currentOption;
          this.close(true);
          this.focusedElement = FOCUS_OPTIONS.input;
          prevent = true;
        }
        break;
      case "Down":
      case "ArrowDown":
        if (this.filteredOptions.length > 0) {
          this.open();
          if (!event.altKey) {
            this.currentOption = this.nextOption;
            this.focusedElement === FOCUS_OPTIONS.listbox;
          }
        }
        prevent = true;
        break;
      case "Up":
      case "ArrowUp":
        if (this.filteredOptions.length > 0) {
          if (this.focusedElement === FOCUS_OPTIONS.listbox) {
            this.currentOption = this.previousOption;
          } else {
            this.open();

            if (!event.altKey) {
              this.currentOption = this.previousOption;
              this.focusedElement = FOCUS_OPTIONS.listbox;
            }
          }
        }
        prevent = true;
        break;
      case "Esc":
      case "Escape":
        if (this.isOpen) {
          this.close(true);
          this.filterValue = this.elements.input.value;
          this.focusedElement = FOCUS_OPTIONS.input;
        } else {
          this.setInputValue("");
        }
        prevent = true;
        break;
      case "Tab":
        this.close(true);
        if (
          this.focusedElement === FOCUS_OPTIONS.listbox &&
          this.currentOption
        ) {
          this.selectedOption = this.currentOption;
        }
        break;

      case "Home":
        this.elements.input.setSelectionRange(0, 0);
        prevent = true;
        break;

      case "End":
        var length = this.elements.input.value.length;
        this.elements.input.setSelectionRange(length, length);
        prevent = true;
        break;

      default:
        break;
    }

    if (prevent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  #onKeyUp(event) {
    if (isPrintableCharacter(event.key)) this.filterValue += event.key;
    if (isAndroidKey(event)) this.filterValue = this.elements.input.value;

    if (this.elements.input.value.length < this.filterValue.length) {
      this.currentOption = null;
    }

    if (event.key === "Escape" || event.key === "Esc") {
      return;
    }
    let prevent = false;
    switch (event.key) {
      case "Backspace":
        this.focusedElement = FOCUS_OPTIONS.input;
        this.#setCurrentOptionStyle(false);
        this.filterValue = this.elements.input.value;
        this.currentOption = null;
        prevent = true;
        break;
      case "Left":
      case "ArrowLeft":
      case "Right":
      case "ArrowRight":
      case "Home":
      case "End":
        this.#setCurrentOptionStyle(false);
        this.focusedElement = FOCUS_OPTIONS.input;
        prevent = true;
        break;
      default:
        if (!isPrintableCharacter(event.key) && !isAndroidKey(event)) break;

        this.focusedElement = FOCUS_OPTIONS.listbox;
        this.#setCurrentOptionStyle(false);
        prevent = true;

        this.currentOption = this.filteredOptions.at(0)?.option;

        if (this.currentOption) {
          if (!this.isOpen && this.elements.input.value.length) {
            this.open();
          }
          this.#setCurrentOptionStyle(this.currentOption);
        } else {
          this.close();
          this.#setActiveDescendant(false);
        }
    }

    if (prevent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  #onBackgroundPointerUp(event) {
    if (!this.isOpen) return;
    if (this.elements.root.contains(event.target)) return;

    this.#setCurrentOptionStyle(null);
    this.focusedElement = null;

    setTimeout(() => {
      // This checks `isOpen` a second time, since the box might have been open, but closed when this callback fires
      if (this.isOpen) this.close(true);
    }, 300);
  }

  #onListboxPointerover() {
    this.hasHover = true;
  }

  #onListboxPointerout() {
    this.hasHover = false;
    setTimeout(this.close.bind(this, false), 300);
  }

  #onOptionClick(event) {
    this.selectedOption = event.target;
    this.close(true);
  }

  #onOptionPointerover(event) {
    this.currentOption = event.target;
  }

  #setActiveDescendant(element) {
    if (element && this.focusedElement === FOCUS_OPTIONS.listbox) {
      this.elements.input.setAttribute("aria-activedescendant", element.id);
      if (!isOptionInView(element)) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    } else {
      this.elements.input.setAttribute("aria-activedescendant", "");
    }
  }

  #setCurrentOptionStyle(currentOption) {
    Object.values(this.elements.listbox.children).forEach((option) => {
      if (option !== currentOption) {
        option.removeAttribute("data-current");
        return;
      }
      option.setAttribute("data-current", "true");
      let optionHeight = option.offsetTop + option.offsetHeight;
      if (
        this.elements.listbox.scrollTop + this.elements.listbox.offsetHeight <
        optionHeight
      ) {
        this.elements.listbox.scrollTop =
          optionHeight - this.elements.listbox.offsetHeight;
      } else if (this.elements.listbox.scrollTop > option.offsetTop + 2) {
        this.elements.listbox.scrollTop = option.offsetTop;
      }
    });
  }

  #setSelectedOptionStyle(currentOption) {
    Object.values(this.elements.listbox.children).forEach((option) => {
      if (option !== currentOption) {
        option.removeAttribute("aria-selected");
        return;
      }
      option.setAttribute("aria-selected", "true");
    });
  }

  #setInputValue(value = "") {
    const trimmed = value.trim();
    this.filterValue = trimmed;
    this.elements.input.value = trimmed;
    this.elements.input.setSelectionRange(trimmed.length, trimmed.length);
  }

  #filterOptions() {
    this.filteredOptions = this.elements.options
      .reduce((acc, option) => {
        const score = getScoreForElement(option, this.filterValue);
        if (score > 0) {
          acc.push({
            score,
            option,
          });
        }
        return acc;
      }, [])
      .sort(compareScoreAndNodes);

    this.elements.listbox.innerHTML = "";
    this.filteredOptions.forEach((el) =>
      this.elements.listbox.appendChild(el.option),
    );
  }

  get nextOption() {
    const currentIndex = this.filteredOptions.findIndex(
      (el) => el.option === this.currentOption,
    );
    return this.filteredOptions.at(
      (currentIndex + 1) % this.filteredOptions.length,
    )?.option;
  }

  get previousOption() {
    const currentIndex = this.filteredOptions.findIndex(
      (el) => el.option === this.currentOption,
    );
    return this.filteredOptions.at(currentIndex - 1)?.option;
  }
}

function getScoreForElement(node, value) {
  if (node.dataset.exemptFromFilter === "true") {
    return 2;
  }

  const lowerCaseValue = value.toLowerCase().trim();
  let text = node.textContent.toLowerCase().trim();
  if (text === lowerCaseValue) return 1;
  if (text.includes(lowerCaseValue)) return 0.7;

  const extraInfo = node.dataset.extraInfo?.toLowerCase().trim();
  if (extraInfo) {
    if (extraInfo === lowerCaseValue) return 0.9;

    const split = extraInfo.split(",").map((el) => el.trim());
    if (split.includes(lowerCaseValue)) return 0.8;
    if (split.some((p) => p.includes(lowerCaseValue))) return 0.6;
  }

  return 0;
}

function isPrintableCharacter(str) {
  return str.length === 1 && str.match(/\S| /);
}

const ANDROID_KEYS = [
  "Process", // Firefox & Chrome
  "Unidentified", // Chrome (but only 127+?)
];

// NOTE: Certain android devices always send an event with a special key and/or keyCode `229`
function isAndroidKey(event) {
  return ANDROID_KEYS.includes(event.key) || event.keyCode == 229;
}

function isOptionInView(element) {
  const { top, left, bottom, right } = element.getBoundingClientRect();
  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function compareScoreAndNodes(o1, o2) {
  let diff = o2.score - o1.score;
  if (diff === 0) {
    diff = compareStrings(
      o1.option.textContent.trim(),
      o2.option.textContent.trim(),
    );
  }

  return diff;
}

function compareStrings(s1, s2) {
  if (s1 > s2) {
    return 1;
  }
  if (s2 > s1) {
    return -1;
  }
  return 0;
}
